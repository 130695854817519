<template>
  <div class="add-parking-container">
    <ContentTitle>
      <span class="return" @click="handleReturn">
        <i class="iconfont icon-a-lujing46"> </i>
        <span>返回</span>
      </span>
      <el-divider direction="vertical"></el-divider>
      新增车场</ContentTitle
    >
    <div class="add-parking-content">
      <el-form
        ref="parkingFormRef"
        :model="parkingFormData"
        :rules="parkingFormRules"
        label-position="top"
        class="add-parking-form"
      >
        <el-form-item prop="parking" label="车场名称">
          <el-input
            v-model="parkingFormData.parking"
            autocomplete="off"
            placeholder="请输入车场名称"
            clearable
          />
        </el-form-item>

        <el-form-item prop="project" label="关联项目">
          <el-select
            v-model="parkingFormData.project"
            placeholder="请选择关联项目"
            clearable
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="chargeType" label="临停收费模板">
          <el-select
            :disabled="!parkingFormData.project"
            v-model="parkingFormData.chargeType"
            placeholder="请选择临停收费模板"
            clearable
            multiple
            collapse-tags
          >
            <el-option
              v-for="item in chargeTypeOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item prop="callCenter" label="呼叫中心">
          <el-input
            v-model="parkingFormData.callCenter"
            autocomplete="off"
            placeholder="请输入呼叫中心"
            clearable
          />
        </el-form-item>
        <el-form-item prop="factor" label="可用厂商">
          <el-select
            v-model="parkingFormData.factor"
            placeholder="请选择可用厂商"
            clearable
          >
            <el-option
              v-for="item in factorOptions"
              :key="item.device_factory_id"
              :label="item.device_factory_name"
              :value="item.device_factory_id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-divider></el-divider>
      <div class="table-container">
        <div class="table-title">道闸管理</div>
        <template v-if="gateMachineTableData.length">
          <Wtable
            :columns="gateMachineColumns"
            :pagination="gateMachinePagination"
            :tableData="gateMachineTableData"
            @delete="deleteGateMachineRow"
          ></Wtable>
        </template>
        <div class="add-btn" @click="handleAddGateMachine">
          <span>新增闸机</span>
        </div>
      </div>
      <!-- <div class="table-container padding-b64">
        <div class="table-title">电桩管理</div>
        <template v-if="electricFittingTableData.length">
          <Wtable
            :columns="electricFittingColumns"
            :pagination="electricFittingPagination"
            :tableData="electricFittingTableData"
            @delete="deleteElectricFittingRow"
          ></Wtable>
        </template>
        <div class="add-btn" @click="handleAddElectricFitting">
          <span>新增电桩</span>
        </div>
      </div> -->
      <div class="footer">
        <el-button
          type="primary"
          class="cancle"
          plain
          round
          @click="handleReturn"
        >
          返回</el-button
        >
        <el-button class="confirm" round @click="handleSure">确认</el-button>
      </div>
    </div>
    <GateMachineDialog
      ref="gateMachineDialog"
      @submit="getGateMachineTableData"
      :needParking="false"
    ></GateMachineDialog>
    <ElectricFittingDialog
      ref="electricFittingDialog"
      @submit="getElectricFittingTableData"
    ></ElectricFittingDialog>
  </div>
</template>
<script>
import { ref, onMounted, watch } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import Wtable from "@/components/wTable/wTable.vue";
import GateMachineDialog from "../components/GateMachineDialog.vue";
import ElectricFittingDialog from "../components/ElectricFittingDialog.vue";
import API from "@/plugins/api.js";
import config from "./addParking.json";

export default {
  components: {
    ContentTitle,
    Wtable,
    GateMachineDialog,
    ElectricFittingDialog,
  },
  setup() {
    const router = useRouter();
    const projectOptions = ref([]);
    const chargeTypeOptions = ref([]);
    const factorOptions = ref([]);
    onMounted(() => {
      chargeTypeOptions.value = [];
      API.getProjectOptions({}).then((res) => {
        projectOptions.value = res;
      });
      API.getFactorOptions({}).then((res) => {
        factorOptions.value = res;
      });
    });
    const parkingFormData = ref({
      parking: "",
      project: "",
      chargeType: [],
      companyName: "",
      callCenter: "",
      factor: "",
    });
    watch(
      () => parkingFormData.value.project,
      (v) => {
        if (v) {
          parkingFormData.value.chargeType = [];
          API.getChargeTemplateOptions({ project_id: v }).then((res) => {
            chargeTypeOptions.value = res;
          });
        }
      },
      {
        deep: true,
      }
    );

    const parkingFormRules = {
      parking: [{ required: true, message: "请输入车场名称", trigger: "blur" }],
      project: [{ required: true, message: "请选择关联项目", trigger: "blur" }],
      chargeType: [
        {
          required: true,
          message: "请选择临停收费模板",
          trigger: "blur",
        },
      ],
      callCenter: [
        { required: true, message: "请输入呼叫中心", trigger: "blur" },
      ],
      factor: [{ required: true, message: "请选择可用厂商", trigger: "blur" }],
    };
    function handleReturn() {
      router.push("/parking");
    }
    const parkingFormRef = ref(null);
    const gateMachineColumns = config.gateMachineTableConfig.list;
    const gateMachinePagination = config.gateMachineTableConfig.pagination;
    const gateMachineTableData = ref([]);
    const gateMachineDialog = ref(null);
    function handleAddGateMachine() {
      gateMachineDialog.value.openDialog();
    }
    function deleteGateMachineRow(data) {
      gateMachineTableData.value.splice(data.$index, 1);
    }
    function getGateMachineTableData(data) {
      const lastData = JSON.parse(JSON.stringify(data));
      lastData.status == 0
        ? (lastData.status = false)
        : (lastData.status = true);
      gateMachineTableData.value.push(lastData);
      gateMachineDialog.value.closeDialog();
    }
    function handleSure() {
      if (parkingFormRef.value) {
        parkingFormRef.value.validate((valid) => {
          if (valid) {
            let gateMachine = [];
            if (gateMachineTableData.value.length) {
              gateMachineTableData.value.forEach((el) => {
                gateMachine.push({
                  sn: el.sn,
                  name: el.name,
                  gate_position_id: el.gate_position_id,
                  gate_type_id: el.gate_type_id,
                  charge: el.charge,
                  status: el.status,
                });
              });
            }
            const parmas = {
              name: parkingFormData.value.parking,
              project_id: parkingFormData.value.project,
              template_id: parkingFormData.value.chargeType,
              call_tel: parkingFormData.value.callCenter,
              device_factory_id: parkingFormData.value.factor,
              gate: gateMachine,
            };
            API.addParking(parmas).then(() => {
              gateMachineTableData.value = [];
              parkingFormData.value = {
                parking: "",
                project: "",
                chargeType: [],
                companyName: "",
                callCenter: "",
                factor: "",
              };
              ElMessage.success({
                message: "操作成功",
              });
            });
          }
        });
      }
    }

    const electricFittingDialog = ref(null);
    const electricFittingColumns = config.electricFittingTableConfig.list;
    const electricFittingPagination =
      config.electricFittingTableConfig.pagination;
    const electricFittingTableData = ref([]);
    function handleAddElectricFitting() {
      electricFittingDialog.value.openDialog();
    }
    function getElectricFittingTableData(data) {
      electricFittingTableData.value.push(data);
    }

    function deleteElectricFittingRow(data) {
      electricFittingTableData.value.splice(data.$index, 1);
    }

    return {
      handleReturn,
      parkingFormRules,
      parkingFormData,
      parkingFormRef,
      handleSure,
      gateMachineColumns,
      gateMachineTableData,
      handleAddGateMachine,
      gateMachineDialog,
      getGateMachineTableData,
      deleteGateMachineRow,
      electricFittingDialog,
      getElectricFittingTableData,
      electricFittingColumns,
      electricFittingTableData,
      handleAddElectricFitting,
      deleteElectricFittingRow,
      electricFittingPagination,
      gateMachinePagination,
      projectOptions,
      chargeTypeOptions,
      factorOptions,
    };
  },
};
</script>

<style lang='scss' scoped>
.add-parking-container {
  height: 100%;
  background-color: #fff;
  padding: 0 20px;
  position: relative;
  .return {
    font-weight: normal;
    cursor: pointer;
    > span {
      color: var(--text-third-color);
      font-size: 13px;
      margin-left: 6px;
    }
    &:hover {
      font-weight: bold;
      color: var(--theme-color);
      > span {
        color: var(--theme-color);
      }
    }
  }
  .add-parking-content {
    .footer {
      display: flex;
      margin-left: 37%;
      position: absolute;
      bottom: 20px;
      .el-button {
        padding: 12px 60px;
        background-color: var(--theme-color);
      }
      .cancle {
        border: 2px solid var(--theme-color);
        color: var(--theme-color);
        background-color: #ffffff;
        margin-right: 80px;
        &:hover {
          color: #ffffff;
          background-color: var(--theme-color);
          border: 2px solid var(--theme-color);
        }
      }

      .confirm {
        color: #ffffff;
        border: 2px solid var(--theme-color);
        &:hover {
          opacity: 0.8;
        }
      }
    }
    .add-parking-form {
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      .el-form-item {
        width: 18%;
      }
    }
    .padding-b64 {
      padding-bottom: 64px;
    }
    .table-container {
      .table-title {
        text-align: justify;
        color: var(--text-third-color);
      }
      .add-btn {
        background-color: var(--theme-bg-color);
        padding: 14px 0;
        margin: 4px 0 20px 0;
        cursor: pointer;

        > span {
          color: var(--theme-color);
          padding: 4px 10px;
          border: 1px solid var(--theme-color);
          border-radius: 10px;
          font-size: 13px;
          &:hover {
            color: #ffffff;
            background-color: var(--theme-color);
            border: 1px solid var(--theme-color);
          }
        }
      }
    }
  }
}
</style>